.ag-theme-material {
  font-family: 'IBM Plex Sans', sans-serif;
  --ag-row-hover-color: #f0fdff;
  --ag-selected-row-background-color: #e2f0f3;
  --ag-odd-row-background-color: #f5f5f5;
  --ag-border-color: #c7d1d4;
  --ag-secondary-border-color: #c7d1d4;
  --ag-range-selection-border-color: transparent;
  --ag-value-change-value-highlight-background-color: #e2f0f3;
}

.ag-theme-material .ag-row .ag-cell {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 8px;
  padding-right: 8px;
  line-height: unset;
  white-space: normal;
  text-align: left;
  border: none;
}

.ag-theme-material .ag-root-wrapper .ag-row .ag-cell.ag-cell-focus {
  border: none;
  background: var(--ag-selected-row-background-color);
}

.ag-theme-material .ag-cell[aria-colindex='1'] {
  padding-left: 24px;
}

.ag-theme-material .ag-header {
  border-top: 1px solid var(--ag-border-color);
}

.ag-theme-material .ag-header-cell-text {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #3e3e3e;
}

.ag-popup .ag-popup-editor {
  border-radius: 8px;
  overflow: auto;
}

.ag-popup .ag-popup-child:not(.ag-tooltip-custom) {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 -4px 20px 0 rgb(0 0 0 / 19%);
}

.ag-theme-material .ag-header-cell {
  padding-left: 8px;
  padding-right: 8px;
}

.ag-theme-material .ag-header-cell[aria-colindex='1'] {
  padding-left: 24px;
}

.ag-theme-material .ag-row {
  border: none;
}

.ag-theme-material .ag-large-text-input {
  padding: 0;
}

.ag-theme-material .ag-large-text-input .ag-text-area-input {
  padding: 8px;
  min-height: 100px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  font-family: Helvetica Neue, Arial, sans-serif;
}

.ag-theme-material .ag-floating-top {
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.ag-root-wrapper {
  overflow: visible;
}

.ag-theme-material .ag-cell-wrapper > :not(.ag-cell-value):not(.ag-group-value) {
  align-self: stretch;
}

.ag-theme-material .ag-ltr .ag-group-contracted {
  margin-right: 20px;
}
