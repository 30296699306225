.ag-theme-material .ag-cell.title-cell p {
  font-weight: 500;
}

.ag-theme-material .ag-cell.photos-cell {
  padding-top: 8px;
}

.ag-theme-material .ag-header-cell.urgent-header-cell .ag-header-cell-comp-wrapper {
  text-align: initial;
}

.ag-theme-material .ag-cell.location-details-cell,
.ag-theme-material .ag-cell.description-cell {
  color: #868686;
}

.ag-theme-material .ag-row.row-draft {
  opacity: 0.5;
}

.ag-theme-material .ag-row-pinned.ag-row.row-draft {
  opacity: 1;
}
