.gantt-task-bar-resize-handle,
.gantt-task-bar-resize-bg,
.gantt-task-bar-label {
  display: none;
}

.gantt-task-bar-bar {
  cursor: default;
}

.gantt-body .gantt-trigger {
  width: 130px;
}

.gantt-time-axis-major-label,
.gantt-time-axis-minor-label {
  text-transform: capitalize;
}
